$(document).foundation();

// process register form
$(document).on('click', '#submit-form', function (event) {
   // $('.preloader').addClass('show-me');
    $('.error-block').remove();
    $('.has-error').removeClass('has-error');

    var zgoda1 ='';
    var zgoda2 ='';

    if ($('input[name=zgoda1]').is(":checked")) {
        zgoda1=$('input[name=zgoda1]').val();
    }

    if ($('input[name=zgoda2]').is(":checked")) {
        zgoda2=$('input[name=zgoda2]').val();
    }



    // get the form data
    var formData = {
        'imie': $('input[name=imie]').val(),
        'nazwisko': $('input[name=nazwisko]').val(),
        'organizacja': $('input[name=organizacja]').val(),
        'stanowisko': $('input[name=stanowisko]').val(),
        'email': $('input[name=email]').val(),
        'miejscowosc': $('input[name=miejscowosc]').val(),
        'zgoda1': zgoda1,
        'zgoda2': zgoda2,
        'token': $('input[name=token]').val(),
        'formtype': $('input[name=formtype]').val(),

    };

    // process the form
    $.ajax({
        type: 'POST',
        url: 'save.php',
        data: formData,
        dataType: 'json',
        encode: true
    })

    // using the done promise callback
        .done(function (data) {

            //$('.preloader').removeClass('show-me');
            // log data to the console so we can see
            //console.clear();
            //console.log(data);

            // errors and validation messages

            if (!data.success) {

                //iterate through errors
                // for each matching input display error
                $.each(data.errors, function (index, value) {
                    $("input[name=" + index + "]").removeClass('filled');
                    $("input[name=" + index + "]").parent('p').addClass('has-error').append('<div class="error-block">' + value + '</div>');
                    if (index == 'email') {

                    }
                });

            } else {

                // ALL GOOD! Show the success message!
                $('#register-form').hide();
                $('.register-success').toggleClass('hide-me');
            }
            // end errors and validation messages

        });

    // stop the form from submitting the normal way and refreshing the page
    event.preventDefault();
});

var tmpval ='';
$('input[type="text"]').blur(function(){
    tmpval = $(this).val();
    if(tmpval == '') {

        $(this).removeClass('filled');
    } else {
        $(this).addClass('filled');

    }
});